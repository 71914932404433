@use "var" as *;

.landing-section {
  height: calc(95vh - 5vw);
  position: relative;
  margin-bottom: 10em;
}


#clip-board {
  fill: $primaryDark;
}


.premium-gabe {
  width: 100%;
  height: 100%;
  padding-top: clamp(50px, 20vw, 80px);
  max-width: 560px;
  margin: 0 auto;

  h1 {
    font-size: clamp(3rem, 15vw, 6rem);
    font-family: HelveticaDisplayNow, sans-serif !important;
    text-transform: uppercase;
    color: $primaryDark;
    line-height: .7;
    letter-spacing: .04em;

    .migra {
      font-family: Migra !important;
      font-size: clamp(2rem, 16vw, 6.3rem);
    }
  }


  .motion-rings {
    fill: $myRed;
  }


  .landing-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 95%;
    width: 100%;
    padding-top: clamp(50px, 20vw, 50px);

    // .text-container:hover {
    //   p {
    //     color: $myRed ;
    //     transition: .5s;
    //   }

    //   #developer {
    //     font-size: 1.5em;
    //     font-weight: bold;
    //     letter-spacing: 1.5rem;
    //   }

    //   #designer {
    //     font-size: 1.5em;
    //     font-weight: bold;
    //     letter-spacing: 1.5rem;
    //   }

    //   #red-bar {
    //     background-color: $primaryLight;
    //   }

    //   #ampersand {
    //     color: $primaryDark !important;
    //     -webkit-text-stroke: 0px;
    //   }

    // }

    .title-section {
      .gabriel {
        display: flex;
        gap: 1.5em;
        position: relative;
        justify-content: space-between;

        .anime-star {
          fill: $myRed;
          position: absolute;
          height: .5em;
          left: 78%;
          top: -.1em;
        }
      }

      .kelly {
        display: flex;
        height: fit-content;
        position: relative;
        margin-top: 1em;

        .anime-star {
          fill: $myRed;
          position: absolute;
          height: .5em;
          right: 83%;
          bottom: -.1em;
        }

        #kelly {
          box-sizing: border-box;
        }

        .icon-container {
          border-radius: 100px;
          height: clamp(2em, 11vw, 70px);
          width: 100%;
          margin-left: 1rem;
          border: solid 1px $primaryDark;
          display: flex;
          align-items: center;
          justify-content: space-around;

          svg {
            height: 65%;
            width: 15%;
            fill: $primaryOffLight;
            transition: ease-out .3s;

            &:nth-child(1) {
              transform: rotate(20deg) translateY(.3em);

              &:hover {
                transform: translateY(.4em) translateX(.5em) rotate(40deg);
              }
            }

            &:nth-child(2) {
              transition: ease-in-out 1.4s;
              fill: $primaryOffLight;

              &:hover {
                transform: scaleX(-1) scale(1.2);
              }
            }

            &:nth-child(3) {
              transform: scaleX(-1) rotate(20deg) translateY(.3em);

              &:hover {
                transform: translateY(.8em) scaleX(-1) rotate(20deg);
                fill: $myRed;
              }
            }
          }
        }
      }
    }

    .bottom-section {
      display: flex;
      justify-content: flex-end;
      height: clamp(10vh, 40vh, 50vh);
    }



    .small-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: right;
      flex-direction: column-reverse;

      .text-container {
        position: relative;
        display: flex;
        flex-direction: row;
      }

      #text {
        display: flex;
        width: fit-content;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;
      }

      #designer {
        display: flex;
        justify-content: flex-end;
        z-index: 1;
      }

      #red-bar {
        // position: absolute;
        width: .4em;
        height: 95%;
        background-color: $myRed;
        margin-left: 1em;
        top: -1em;
        right: -.5em;
      }

      #developer {
        margin-bottom: 4vw;
        z-index: 1;
        line-height: 1.4em;
      }

      #ampersand {
        font-family: Migra;
        display: flex;
        position: absolute;
        top: -.1em;
        color: #E2E1E1;
        -webkit-text-stroke: 1px $myRed;
        font-weight: 800;
        font-size: clamp(6rem, 19vw, 40vw);
        line-height: .8em;
        height: fit-content;
      }

      .paragraph {
        align-self: flex-end;

        p {
          margin: 0;
          text-transform: lowercase;
          font-size: clamp(1em, 3vw, 2em);
          margin-right: .4em;
        }
      }

      p {
        text-transform: uppercase;
        margin: 0;
        font-size: clamp(1.2rem, 4vw, 4rem);
        transition: 1s;
        width: fit-content;
      }
    }
  }
}


@media screen and (min-width:600px) {}

@media screen and (min-width: 700px) {
  .premium-gabe {
    max-width: 1900px;
    display: flex;
    gap: 8em;
    height: 100%;
    padding: 0;
    justify-content: center;

    .motion-rings {
      height: 10vw;
      width: 30%;
    }

    h1 {
      font-size: clamp(1rem, 15vw, 15rem);
      line-height: .8;
    }

    .landing-text {
      .title-section {
        .kelly {
          .icon-container {
            height: clamp(2em, 12.2vw, 13vw);
          }
        }
      }

      .small-text {
        flex-direction: row;
        height: fit-content;
        flex: 2 1 auto;
        align-self: flex-end;
        padding-bottom: 2em;

        #red-bar {
          margin-left: 0em;
          width: .2em;
        }

        #text {
          justify-content: flex-end;
          height: fit-content;
        }

        #ampersand {
          font-size: clamp(3em, 19vw, 20vw);
        }

        #development {
          margin-bottom: 6vw;
        }

        p {
          font-size: clamp(2em, 3vw, 13vw);
          margin-right: .5em;
        }

        .paragraph {
          p {
            align-self: flex-end;
            font-size: clamp(1em, 2vw, 4em);
            text-transform: capitalize;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .premium-gabe {
    h1 {
      font-size: clamp(1rem, 15vw, 13rem);
      line-height: .8;
    }

    .landing-text {
      .small-text {
        #red-bar {
          height: 100%;
          width: .3em;
        }

        p {
          font-size: clamp(2em, 2vw, 20em);
        }

        #ampersand {
          font-size: clamp(2em, 15vw, 20em);
          left: -.1em;
        }

        .paragraph {
          p {
            font-size: clamp(1em, 1.9vw, 10em)
          }
        }
      }
    }
  }
}



@media screen and (min-width: 1400px) {
  .premium-gabe {
    h1 {
      font-size: clamp(1rem, 11vw, 13rem);
      line-height: .8;
    }

    .motion-rings {
      height: clamp(5em, 9vw, 10em);
      width: 35%;
    }

    .landing-text {
      .title-section {
        max-width: 66vw;

        .kelly {
          .icon-container {
            height: clamp(2em, 9vw, 13vw);
          }
        }
      }
    }

    .landing-text {
      .small-text {
        p {
          font-size: clamp(2em, 2vw, 20em);
        }



        #ampersand {
          font-size: clamp(2em, 12.5vw, 20em);
          left: -.2em;
          -webkit-text-stroke: 2px $myRed;
        }

        .paragraph {
          p {
            font-size: clamp(1em, 1.3vw, 10em)
          }
        }
      }
    }
  }

}

@media screen and (min-width: 1900px) {
  .premium-gabe {
    margin: 0;

    h1 {
      font-size: clamp(1rem, 8vw, 15rem);
      line-height: .8;
    }

    .motion-rings {
      height: clamp(5em, 9vw, 10em);
      width: 35%;
    }

    .landing-text {
      .title-section {
        max-width: 50vw;

        .kelly {
          .icon-container {
            height: clamp(2em, 6.5vw, 13vw);
          }
        }
      }
    }

    .landing-text {
      padding-top: 6em;

      .small-text {
        p {
          font-size: clamp(2em, 1.5vw, 20em);
        }

        #ampersand {
          font-size: clamp(2em, 10.6vw, 20em);
          left: -.3em;
          -webkit-text-stroke: 2px $myRed;
        }

        .paragraph {
          p {
            font-size: clamp(1em, 1.3vw, 10em)
          }
        }
      }
    }
  }
}