@use "var" as *;

body {
  overflow-x: hidden;
  background-color: $primaryLight;
  cursor: url()
}

main {
  // overflow-x: hidden;
}



a {
  cursor: none;
}

.sr-only {
  display: none !important;
}

#cursor {
  display: none;
  transition: .2s;
  position: relative;
  background-color: $primaryDark;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 9999999;

  p {
    font-family: Migra;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 50%;
    width: fit-content;
    transform: translateY(-50%);
    font-size: 4px;
    color: $primaryDark;
    z-index: 20;
    transition: .2s ease-out;
  }
}

main {
  margin: 0 auto;
  margin-bottom: 5rem;
  padding: 0 5vw;
}


h1 {
  font-size: 4rem;
  margin: 0;
}

h2,
h3,
h4,
a,
li,
p {
  font-family: HelveticaDisplayNow;
  color: $primaryDark;
}

h2 {
  margin-bottom: 0;
}

body {
  margin: 0;
  background-color: $primaryLight;
}

.section-content {
  padding-top: 0;
  min-height: 30vh;

  #email-scroll {
    margin-left: 0px;
    max-width: 100vw;
  }
}

#forever-message {
  position: relative;
  overflow: hidden;
  left: -5.5%;
  margin-bottom: 20rem;
  width: 110vw;
  min-height: 3em;


  #email-scroll {
    animation: scrollText 25s linear infinite;
    width: max-content;
    background-color: $myRed;

    #scroll-text {
      display: flex;
    }
  }

  #email {
    margin: 0rem 2rem 0rem 0;
    text-transform: uppercase;
    font-size: 5.5vw;
    color: $primaryLight;
    font-weight: 100;
    line-height: 8.5vw;
    transition: all 1s ease;
    height: fit-content;
  }
}

#forever {
  color: $primaryDark;
  line-height: 7vw;
  font-weight: 1000;
  margin: 0;
  text-transform: uppercase;
  font-size: clamp(3em, 10vw, 30em);
  transform: translateX(-.5vw)
}

#contact {
  position: relative;
  left: -19%;
  width: 115vw;
  height: 15vh;
  margin: 0;
  z-index: 1;
  background-color: $myRed;
  padding: 0;

  &:hover {
    p {
      -webkit-text-stroke: 2px $primaryLight !important;
      color: $primaryDark;
    }
  }

  .mobile-instructions {
    position: absolute;
    color: #313333;
    top: -105%;
    right: 65%;
    font-size: 2vw;
  }

  .section-content {
    display: flex;

    div {
      text-align: center;
      display: flex;
    }

    p {
      margin: 1rem;
      text-transform: uppercase;
      font-size: 10vw;
      color: $primaryLight;
      transition: all 1s ease;
      cursor: none;
      height: fit-content;
    }
  }

  @keyframes scrollText {
    to {
      transform: translate(-50%);
    }
  }

  .arrow {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: flex-end;
    width: 3em;
    opacity: 1;
    z-index: -2;

    svg {
      transform: scaleY(-1) scaleX(-1);
      fill: $myRed;
      stroke-width: 10px;
    }
  }
}

@media screen and (min-width:768px) {
  main {
    margin: 0;
  }

  #about {
    margin-top: 25vh;

    .section-content {
      p {
        font-size: larger;
        max-width: 50%;
      }

      .three-head {
        max-width: 40%;
        height: 70%;
        margin-left: 50%;
      }
    }
  }

  #contact {
    min-height: 230px;
    height: 15vw;

    .mobile-instructions {
      position: absolute;
      color: #313333;
      top: -39%;
      right: 70%;
      font-size: 2vw;
    }
  }
}


@media screen and (min-width:1000px) {
  .mobile-instructions {
    display: none;
  }

  #cursor {
    display: block;
  }

  #about {
    .section-content {
      p {
        font-size: larger;
        font-size: 25px;
        max-width: 39%;
      }

      .three-head {
        z-index: 200;
        top: 10%;
        max-width: 40%;
        margin-left: 50%;
      }
    }
  }
}

@media screen and (min-width:1400px) {
  #forever-message {
    left: -20.5%;
  }

  #about {
    .section-content {
      .three-head {
        top: 10%;
        max-width: 40%;
        height: 80%;
        margin-left: 50%;
      }
    }
  }
}