@use "var" as *;

main {
  max-width: 2100px;
  margin: 0 auto;

}



html {
  scroll-behavior: smooth;
}

.project {
  margin: 1vw;
}

.default {
  h1 {
    font-size: 6em;
    line-height: 75px;
    font-family: HelveticaDisplayNow, sans-serif;
    color: $primaryDark;
  }

  h2 {
    margin: 0;
  }

  #sub-heading {
    font-size: 1em;
    margin-top: .5em;
  }

  img {
    width: fit-content;
    margin: 5% 0;
    max-height: 40vh;
  }

  h3 {
    margin: 0;
    margin-top: 4rem;
    padding-top: 4rem;
    font-size: 1.5em;
  }

  ul {
    list-style-type: none;
    max-width: 50%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    padding: 0;
  }

  .basic-info {
    padding: 0;
    gap: 50px;

    .github h3 {
      margin-bottom: .5em;
    }

    .github {
      a {
        transition: .3s ease;
        margin-top: 4em;
      }
    }

    a {
      text-decoration: none;
      padding: 5px 8px 5px 8px;
      border: solid 1px #313333;
      border-radius: 15px;

      &:hover {
        background-color: #313333;
        color: $primaryLight;

        svg {
          fill: $primaryLight;
        }
      }
    }
  }


  .project-landing {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: calc(100vh - (40px + 5vw));
    margin-bottom: 4em;
  }

  .live-links {
    margin: 6rem 0;
    text-align: center;

    .live-site {
      width: fit-content;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 70px;

      svg {
        height: 7vw;
        padding-left: 1em;
        fill: $primaryDark;
      }

      p {
        font-size: 10vw;
        margin: 0;
      }
    }

    .vert-nav {
      display: none;
    }

    a {
      text-decoration: none;
      font-size: larger;
    }

    border: solid 1px $primaryDark;
    padding: 5px 8px 5px 8px;
    padding: 2rem;
    border-radius: 15px;
  }
}

.heading-number {
  display: block;
  padding-left: 4px;
  font-size: 2.5em;
  font-family: Migra;
  color: $myRed;
}

.highlights {
  max-width: 1022px;
  padding: 0;

  h2 {
    margin-top: 0;
  }

  h3 {
    margin: 0;
    margin-top: 4rem;
    padding-top: 2rem;
  }

  p {
    margin-bottom: 1em;
  }

  .highlight-section {
    margin-bottom: 20em;
  }

  .code {
    position: relative;
    scroll-padding-top: 100px;
    height: 200px;
    transition: height .5s ease-in-out;
    width: 100%;
  }

  pre {
    border-radius: 10px;
    margin-bottom: 5em;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.375;
    direction: ltr;
    text-align: left;
    white-space: pre-wrap;
    word-spacing: normal;
    word-break: normal;
    tab-size: 4;
    hyphens: none;
    height: 100%;
    transition: height 0.5s ease-in-out;
  }

  .expand-button {
    position: absolute;
    padding: .5em;
    font-family: HelveticaDisplayNow;
    color: $primaryDark;
    bottom: -3em;
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid $primaryDark;
  }

  .expand-button:hover {
    background-color: $primaryDark;
    color: $primaryLight;
  }

  .code.expanded {
    height: 900px;
    max-height: 900px;
    transition: height .5s ease-in-out;
  }

  img {
    margin-top: 1rem;

    width: fit-content;
  }

  video {
    margin-top: 1rem;

    width: fit-content;
    max-height: 450px;
  }
}

@media screen and (min-width:768px) {
  .default ul {
    max-width: 35%;
  }

  .project {
    margin: 0;
  }

  .default p {
    max-width: 65%;
  }
}

@media screen and (min-width:1124px) {

  .project-landing {
    height: calc(100vh - (40px + 2vw)) !important;
    max-width: 70.666%;

    P {
      margin-bottom: 0 !important;
    }
  }

  .default,
  .highlights {
    h2 {
      font-size: clamp(4rem, 8vw, 8rem);
      line-height: 104px;
      max-width: 1000px;
    }

    h3 {
      padding-top: .2em;
      max-width: 35%;
      border-top: solid 1px rgba(0, 0, 0, 0.205);
    }
  }

  .default {
    h1 {
      font-size: clamp(5em, 18vw, 7.6em);
      line-height: .9em;
    }

    .left-side {
      h3 {
        font-size: 2em;
        margin-top: 1em;
      }

      p {
        margin-top: 0;
        margin-bottom: 64px;
        font-size: 25px;
        font-weight: lighter;
        max-width: 85%;
      }

      #sub-heading {
        font-size: 2vw;
      }

      ul {
        max-width: 40%;
        margin-bottom: 4em;
      }

      .basic-info-content {
        margin: 14em 0;
        margin-top: 4em;
        padding-left: 5vw;
        max-width: 900px;
      }
    }

    .live-links {
      position: relative;
      padding: 0;
      margin: 0;
      margin-left: 1em;
      width: 30%;
      border: none;
      border-left: solid 1px $primaryDark;
      border-radius: 0;
      height: 100vh;
      position: fixed;
      top: 0px;
      left: 70%;

      .vert-nav {
        position: absolute;
        display: flex;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        z-index: 999;

        .vert-nav-active {
          background-color: $myRed;
          color: $primaryDark;

          &:hover {
            border: 1px solid $primaryDark;
          }
        }

        .spin-star {
          align-items: center;
          display: block;
          position: absolute;
          transition: linear;
          top: 50%;
          left: -1.5em;
          width: 3em;
          height: 3em;
          position: fixed;
          background-color: $primaryLight;
          border-radius: 50%;
          padding: .5em;

          svg {
            fill: $primaryDark;
          }

        }

        ul {
          height: fit-content;
          padding-left: 2em;
          margin: 0;
          display: grid;
          grid-template-columns: 1fr;
          align-items: baseline;
          justify-items: start;
          font-size: 1em;
          gap: 0;

          li {
            position: relative;
            padding: 5em 0em;
          }

          a {
            display: block;
            color: $secondaryLight;
            transition: .2s ease-out;
            font-family: Migra;
            // border: .001em solid $primaryDark;
            border-radius: 50%;
            padding: .6em;
            height: 5px;
            width: 5px;

            &:hover {
              background-color: $myRed;
              color: $primaryDark;

            }
          }
        }
      }

      .live-site {
        width: fit-content;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 1px $primaryDark;
        padding: 1.5em;
        border-radius: 70px;
        transition: .5s ease;

        svg {
          height: 1.5vw;
          padding-left: 1em;
          fill: $primaryDark;
        }

        p {
          margin: 0;
          font-size: 2vw;
          font-weight: lighter;
          text-transform: uppercase;
          max-width: 100%;
        }
      }

    }
  }

  .highlights {
    padding: 0;
    width: 66%;

    section {
      padding: 0;
    }

    h3 {
      margin-top: 4em;
      line-height: 25px;
    }

    .highlight-content {
      margin-left: 5vw;
    }

    p {
      margin: 1.5em 0;
      font-size: 25px;
      max-width: 85%;
    }
  }

  .live-site:hover {
    background-color: $myRed;
  }
}

@media screen and (min-width:1600px) {

  .default {

    h1 {
      font-size: clamp(5em, 18vw, 12em);
      line-height: .9em;
    }

    img {
      max-height: 40vh;
    }
  }

}