@use "var" as *;

.small-project-card {
  border-bottom: solid 1px $primaryDark;

  &:hover .arrow-container svg {
    fill: $myRed !important;
    transform: translateX(1em);
  }

  &:hover h3 {
    // color: $myRed;
    transform: translateX(.5em);
  }

  &:hover .number {
    transform: translateX(1em);
  }

  a {
    text-decoration: none;
  }

  h3 {
    font-size: clamp(1.5rem, 2vw, 3rem);
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-weight: 100;
    transition: .4s ease-out;
  }

  .text-content {
    padding: 2em 0em 3em 0em;

    .number {
      height: 1em;
      font-size: .8em;
    }

    .arrow-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        height: 1.5em;
        fill: $primaryDark;
        stroke-width: 100;
        transition: .4s ease-out;
      }
    }
  }
}