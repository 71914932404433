@use "var" as *;

footer {
  position: relative;
  background-color: $primaryLight;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid $primaryDark;


  svg {
    fill: #F55555;
    width: 50px;
  }
}