h1 {
  font-size: 75;
}

h2 {
  font-size: 4em;
}

h3 {
  margin: 0;
  margin-top: 4rem;
  padding-top: 4rem;
  font-size: 2em;
}