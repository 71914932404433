@use "var" as *;


#about {
  position: relative;
  margin-bottom: 20rem;
  height: 85vh;

  .title {
    display: flex;
  }

  h2 {
    font-weight: 1000;
    margin: 0;
    font-size: clamp(4rem, 12vw, 20rem);
    text-transform: uppercase;
    font-family: Migra;
    color: $primaryLight;
    -webkit-text-stroke: 1px $primaryDark;
    line-height: clamp(4rem, .8em, 18rem);
    // width: 270px;

  }

  h3 {
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    font-weight: 100;
    font-size: clamp(1.5rem, 5vw, 10rem);
    line-height: .9em;
    transition: .8s;
    min-width: 2.9em;
  }

  .black-line {
    height: 1px;
    width: 100%;
    background-color: $primaryDark;
  }

  .down-arrow {
    display: none;
  }

  .three-head {
    width: 90vw;
    height: 40%
  }
}

@media screen and (min-width:700px) {

  #about {
    height: 46vw;

    .three-head {
      position: absolute;
      top: 2.5em;
      left: 34%;
      height: clamp(10em, 40vw, 30em);
      width: 66%;
    }

    .down-arrow {
      display: block;
      position: absolute;
      top: 50%;

      svg {
        height: 2vw;
        fill: $primaryDark;
        transform: rotate(135deg);
        transition: .4s;
      }
    }

    .dot-container {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 1.5em;
      z-index: 1;

      &:hover .dot {
        transform: scale(1.9);
      }

      &:hover .instructions {
        opacity: 1;
        transform: translateX(-2em);
      }

      .instructions {
        position: absolute;
        top: -1.1em;
        right: -2em;
        transition: .5s ease-out;
        width: 8em;
        opacity: 0;
        font-family: HelveticaDisplayNow;
      }

      .dot {
        margin-left: auto;
        margin-right: 0;
        border-radius: 50%;
        height: .9em;
        width: .9em;
        border: solid 1px $primaryDark;
        background-color: $primaryDark;
        transition: .4s ease-out;
      }
    }

    .who-text {
      position: absolute;
      bottom: 0;
      display: flex;
      gap: 3.5em;
      align-items: end;

      p {
        opacity: 0;
        font-family: HelveticaDisplayNow;
        transition: .4s ease-in;
        width: 30%;
        margin: 0;
      }
    }
  }
}

@media screen and (min-width:1200px) {
  #about {
    height: 40vw;

    .three-head {
      top: 4.5em;
      left: 34%;
      height: clamp(10em, 40vw, 30em);
      width: 66%;
    }

    h2 {
      font-size: clamp(4rem, 18vw, 10rem);
      -webkit-text-stroke: 2px $primaryDark;
    }

    .who-text {
      p {
        font-size: 1.8vw;
      }
    }
  }

}

@media screen and (min-width:2200px) {
  #about {
    height: 30vw;

    .three-head {
      top: 4.5em;
      left: 34%;
      height: clamp(10em, 40vw, 42em);
      width: 66%;
    }

    .down-arrow {
      display: block;
      position: absolute;
      top: 50%;

      svg {
        height: 1.2vw;
        fill: $primaryDark;
        transform: rotate(135deg);
        transition: .4s;
      }
    }

    h2 {
      font-size: clamp(4rem, 29vw, 15rem);
      width: 3.4em;
    }

    .who-text {
      h3 {
        font-size: 5em;
      }

      p {
        font-size: 1.8em;
      }
    }
  }
}