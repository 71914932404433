@use "var" as *;

header {
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5vw;
  padding-top: 5vw;
  padding-bottom: 2vw;

  button {
    height: 20px;
  }

  #hamburger-svg {
    stroke: $myRed;
  }

  .hamburger {
    height: 100%;
    display: flex;
    justify-content: center;
    transition: 0.5s;
    transition: width 2s, height 4s;

    .ham-button {
      height: 40px;
    }



    .hamburger-container {
      background-color: $myRed;
      margin: 0;
      position: absolute;
      top: 0;
      right: 0px;
      transition: .8s ease;
      width: 100vw;
      height: 100vh;

      ul {
        list-style: none;
        margin-top: 180px;
        padding-left: 0pt;
        display: flex;
        flex-direction: column;
        align-items: end;
      }

      li {
        width: fit-content;
        padding: 1rem;
        font-size: 10vh;
        margin-right: 5vw;
      }

      a {
        text-decoration: none;
        color: white;
        transition: .2s ease-in-out;

        &:hover {
          color: $primaryDark;
        }
      }
    }
  }

  .right-side-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      height: 100%;
      font-family: HelveticaDisplayNow;
      background-color: inherit;
      margin-right: 23vw;
      padding: 1px 12px 1px 12px;
      border: solid 1px $primaryDark;
      border-radius: 15px;
    }
  }


  .pg-logo {
    position: relative;
    width: 60px;
    height: fit-content;
    fill: $primaryDark;
    z-index: 9999999;
    mix-blend-mode: difference;
    transition: .5s ease;

    svg {
      mix-blend-mode: difference;
    }

    &:hover {
      transform: rotate(-25deg);
      fill: #F55555;
    }
  }

}

@media screen and (min-width:768px) {
  .hamburger-container {
    max-width: 38vw;
  }
}

@media screen and (min-width:1024px) {
  header {
    padding-top: 2vw;
  }

  header .right-side-header {
    max-width: 200px;

    button {
      margin-right: 0;
    }
  }
}