@font-face {
  font-family: HelveticaDisplayNow;
  src: url(../fonts/HelveticaNowDisplay-Medium.woff);
  font-display: swap;
}

@font-face {
  font-family: HelveticaDisplayNowItalic;
  src: url(../fonts/HELVETICANOWDISPLAY-REGITA.TTF);
  font-display: swap;
}

@font-face {
  font-family: MigraExtraLight;
  src: url(../fonts/._Migra-Extralight.woff);
  font-display: swap;
}

@font-face {
  font-family: MigraBold;
  src: url('../fonts/Migra-Extrabold.woff2') format('woff2'),
    url('../fonts/Migra-Extrabold.woff') format('woff'),
    url('../fonts/MIGRA-EXTRABOLD.OTF') format('opentype');
  font-display: swap;
}